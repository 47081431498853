import React from "react";
import header from "../../assets/header-min.webp";
import { HeaderImage, Headline, Tagline, Wrapper } from "./styled";

type HeaderProps = {
  tagline?: string | React.ReactNode;
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  headline?: string | React.ReactNode;
};

function Header({ tagline, title, subtitle, headline }: HeaderProps) {
  return (
    <Wrapper>
      <HeaderImage>
        <img src={header} alt="" />
      </HeaderImage>
      {tagline && <Tagline>{tagline}</Tagline>}
      <h1>{title}</h1>
      {subtitle && <h5>{subtitle}</h5>}
      {headline && <Headline>{headline}</Headline>}
    </Wrapper>
  );
}

export { Header, type HeaderProps };
