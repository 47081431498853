import React from "react";
import {
  BlockCounter,
  BlockFooterTagline,
  BlockImageWrapper,
  BlockText,
  BlockTitle,
  BlockWrapper,
} from "../shared/styled";
import Header from "../components/Header";
import Button from "../components/Button";
import first from "../assets/1-min.png";
import second from "../assets/2-min.png";
import third from "../assets/3-min.png";
import fourth from "../assets/4-min.png";
import Footer from "../components/Footer";

function SecondVersion() {
  React.useEffect(() => {
    window.ym(93807289, "reachGoal", "v2_show");
  }, []);
  return (
    <main>
      <BlockWrapper>
        <Header
          tagline="Welcome to"
          title="TwinGPT "
          subtitle="The Future of AI Communication"
        />
        <p style={{ textAlign: "left" }}>
          Engage, customize, earn, and interact like never before. Be part of
          the $NICE token revolution!
        </p>
        <Button
          onClick={() => window.ym(93807289, "reachGoal", "cta_v2_click")}
          style={{ marginTop: 50 }}
          label={
            <a href="ncg://special-offer-click?url=tg://resolve?domain=twingpt_chat">
              Join Our Telegram Chat
            </a>
          }
        />
      </BlockWrapper>
      <BlockWrapper>
        <BlockCounter />
        <div style={{ bottom: 0, top: "unset" }} className="cloud" />
        <BlockTitle textLeft>AI Chat Experience</BlockTitle>
        <BlockText textLeft>
          Unleash your creativity with TwinGPT. Customize your AI chat
          experience and earn $NICE tokens. Be the first to know!
        </BlockText>
        <BlockFooterTagline>
          <div>Stay Updated</div>
        </BlockFooterTagline>
        <Button
          onClick={() => window.ym(93807289, "reachGoal", "cta_v2_click")}
          label={
            <a href="ncg://special-offer-click?url=tg://resolve?domain=twingpt_chat">
              Join Our Telegram Chat
            </a>
          }
        />
        <BlockImageWrapper style={{ marginTop: 24 }}>
          <img src={first} alt="" />
        </BlockImageWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <BlockCounter />
        <BlockTitle textLeft>Rewards & NICE Token</BlockTitle>
        <BlockText textLeft>
          Revolutionize your AI experience with TwinGPT. Join us and earn
          rewards in $NICE tokens. Stay tuned!
        </BlockText>
        <Button
          onClick={() => window.ym(93807289, "reachGoal", "cta_v2_click")}
          label={
            <a href="ncg://special-offer-click?url=tg://resolve?domain=twingpt_chat">
              Join Our Telegram Chat
            </a>
          }
        />
        <BlockImageWrapper style={{ marginTop: 24 }}>
          <img src={second} alt="" />
        </BlockImageWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <BlockCounter />
        <div className="cloud" />
        <BlockTitle textLeft>Customize Your AI Experience</BlockTitle>
        <BlockText textLeft>
          Unleash your creativity with TwinGPT. Customize your AI chat
          experience and earn $NICE tokens. Be the first to know!
        </BlockText>
        <BlockFooterTagline>
          <div>Discover more</div>
        </BlockFooterTagline>
        <Button
          onClick={() => window.ym(93807289, "reachGoal", "cta_v2_click")}
          label={
            <a href="ncg://special-offer-click?url=tg://resolve?domain=twingpt_chat">
              Join Our Telegram Chat
            </a>
          }
        />
        <BlockImageWrapper>
          <img src={third} alt="" />
        </BlockImageWrapper>
      </BlockWrapper>
      <BlockWrapper>
        <BlockCounter />
        <BlockTitle textLeft>Chat with Famous Personalities</BlockTitle>
        <BlockText textLeft>
          Ever wanted to chat with your idol? TwinGPT can make it happen. Follow
          us for updates on our $NICE token launch!
        </BlockText>
        <BlockFooterTagline>Experience it Firsthand</BlockFooterTagline>
        <Button
          onClick={() => window.ym(93807289, "reachGoal", "cta_v2_click")}
          label={
            <a href="ncg://special-offer-click?url=tg://resolve?domain=twingpt_chat">
              Join Our Telegram Chat
            </a>
          }
        />
        <BlockImageWrapper>
          <img src={fourth} alt="" />
        </BlockImageWrapper>
      </BlockWrapper>
      <Footer
        onClick={() => window.ym(93807289, "reachGoal", "cta_v2_click")}
      />
      <div className="bottom-blur" />
    </main>
  );
}

export default SecondVersion;
