import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 15px 10px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 25px;
  width: calc(100% + 16px);
  margin-left: -8px;

  > img {
    display: block;
    margin: 25px auto;
  }

  > p {
    margin: 25px 0 50px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #ffffff;
  }
`;
