import styled, { css } from "styled-components";

export const BlockText = styled.div<{ textLeft?: boolean }>`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.2px;
  color: rgba(255, 255, 255, 0.8);
  margin: 24px 0 50px 0;

  ${(props) =>
    props.textLeft &&
    css`
      text-align: left;
    `}
`;
export const BlockTitle = styled.div<{ textLeft?: boolean }>`
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #ffffff;
  margin: 24px 0;

  ${(props) =>
    props.textLeft &&
    css`
      text-align: left;
    `}
`;
export const BlockImageWrapper = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
    width: auto;
  }
`;

export const BlockFooterTagline = styled.div`
  margin: 15px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  background: linear-gradient(87.09deg, #f1a4f9 0%, #8f00ff 120.72%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 25px;
  white-space: nowrap;

  > div {
    flex: 1 1 100%;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background: #272729;
  }
`;

export const BlockCounter = styled.div`
  background: #ac85d6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 4px 8px;
  margin-bottom: 4px;

  &:before {
    content: "0" counter(section);
    display: block;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
`;
export const BlockWrapper = styled.div<{ showCounter?: boolean }>`
  position: relative;
  margin-bottom: 100px;
  text-align: center;
  counter-increment: section;

  &:has(> ${BlockCounter}) {
    > ${BlockTitle} {
      margin-top: 0;
    }

    > ${BlockImageWrapper} {
      margin-top: 24px;
    }

    .cloud {
      top: unset;
      bottom: 0;
    }
  }

  .cloud {
    display: block;
    z-index: -2;
    position: absolute;
    right: 0;
    top: 0;
    height: 400px;
    width: 500px;
    background: radial-gradient(
      106.57% 51.66% at 95.92% 46.6%,
      #f1a4f9 0%,
      #8f00ff 30.91%,
      #1a0236 100%
    );
    filter: blur(45px);
    transform: translateX(30%);
  }

  ${(props) =>
    props.showCounter &&
    css`
      &:before {
        position: absolute;
        right: -16px;
        top: 50%;
        content: "0" counter(section);
        display: block;
        font-weight: 700;
        font-size: 88px;
        line-height: 104px;
        letter-spacing: -2.5px;
        color: rgba(255, 255, 255, 0.1);
        transform: rotate(-90deg) translate(-30%, 10px);
        z-index: -1;
      }
    `}
`;

export const Alert = styled.div`
  margin: 32px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.15);
  border: 1px dashed rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  color: white;
  text-align: center;

  span {
    color: var(--primary-color);
  }
`;

export const ImageWrapper = styled.div`
  max-height: 200px;
  border-radius: 6px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
