import styled from "styled-components";

export const HeaderImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  max-height: 375px;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Tagline = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #eff4f7;
  text-align: center;

  span {
    color: var(--primary-color);
  }
`;

export const Headline = styled.div`
  margin: 24px auto;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 14px 24px;
  width: fit-content;
  border-top: 1px solid #272729;
  border-bottom: 1px solid #272729;
  color: white;
`;

export const Wrapper = styled.div`
  position: relative;
  padding: 330px 0 0 0;
  margin-bottom: 32px;
  margin-left: -16px;
  width: calc(100% + 32px);

  h1,
  h5 {
    text-align: center;
  }
`;
