import React from "react";
import logo from "../../assets/logo.svg";
import Button from "../Button";
import { Wrapper } from "./styled";

type FooterProps = { onClick?: () => void };

function Footer({ onClick = () => {} }: FooterProps) {
  return (
    <Wrapper>
      <img width={120} height={25} src={logo} alt="" />
      <p>
        Don't miss out on the future of AI communication! Stay updated on the
        latest news and ask any questions you might have
      </p>
      <Button
        onClick={onClick}
        label={
          <a href="ncg://special-offer-click?url=tg://resolve?domain=twingpt_chat">
            Join Our Telegram Chat
          </a>
        }
      />
    </Wrapper>
  );
}

export { Footer, type FooterProps };
