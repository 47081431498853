import React from "react";
import { useDebounce } from "use-debounce";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  FirstVersion,
  SecondVersion,
  ThirdVersion,
  FourthVersion,
} from "./pages";

function App() {
  const [height, setHeight] = React.useState(window.innerHeight);
  const [debouncedValue] = useDebounce(height, 250);

  React.useEffect(() => {
    const appHeight = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", appHeight);
    appHeight();
  }, [setHeight]);

  React.useEffect(() => {
    const doc = document.documentElement;

    doc.style.setProperty("--app-height", `${debouncedValue}px`);
  }, [debouncedValue]);

  return (
    <Routes>
      <Route path="/v1" element={<FirstVersion />} />
      <Route path="/v2" element={<SecondVersion />} />
      <Route path="/v3" element={<ThirdVersion />} />
      <Route path="/v4" element={<FourthVersion />} />
      <Route path="*" element={<Navigate to="/v1" replace />} />
    </Routes>
  );
}

export default App;
